import React from "react"

import * as styles from "../styles/layouttrainings.module.scss"
import "../styles/staticimages.scss"

const Layouttrainings = props => {
  console.log(props.imageSrc)

  return (
    <div id={styles.container}>
      <section>
        <div id={styles.bar1}>
          <h1 id={styles.header}>{props.title}</h1>
        </div>
      </section>

      <section id={styles.bar2}>
        <div id={styles.container}>
          <div id={styles.description}>
            <h2>{props.subTitle}</h2>

            <p>{props.description}</p>
          </div>

          <div id={styles.picture}>
            {/*Mivel a Staticimage nem tudja átvenni a kép forrását props változóban, ezért a stílust változtatom dinamikusan az átvett props.imageSrc  változóval. Az egyes háttérképek a "staticimages.scss" stíluslapon találhatók meg.  */}
            <div className={`images ${props.imageSrc}`}></div>
          </div>

          <div id={styles.places}>
            <h2>Helyszínek</h2>
            {props.places.map((obj, ind) => (
              <div id={styles.details} key={ind}>
                <h3>{obj.place}</h3>
                <p> {obj.date}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* <section>
        <div id={styles.bar3}>
          <div id={styles.video}>YOUR VIDEO</div>
        </div>
      </section> */}
    </div>
  )
}

export default Layouttrainings
