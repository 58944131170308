import * as React from "react"

import Layout from "../components/layout"
import Layouttraining from "../components/layouttraining"
import Seo from "../components/seo"

const FitnessCamps = () => {
  let title = "Fitnesz tábor felnőtteknek"
  let subTitle = "Hogy a sport ne csak az edzésről szóljon"
  let places = [
    {
      place: "Következő tábor - tervezés alatt",
      date: "2022 tavasz",
    },
  ]

  let description = (
    <span>
      <b>
        Két nap kőkemény változatos edzésprogram egy kellemes helyen, ahol nem
        csak elfáradhatsz, de barátságokra is szert tehetsz.
      </b>{" "}
      Sok edzés, sok mulatság, egészséges ételek és italok kóstolója, szakmai
      előadások az egészséges életmódról. Immár a 4. fitnesz táboromat szervezem
      és remélem nem az utolsót. Ha érdekel, időben jelentkezz, mert a
      résztvevők száma korlátozott.
    </span>
  )
  let imageSrc = "camp"

  return (
    <Layout>
      <Seo title={title} />
      <Layouttraining
        title={title}
        subTitle={subTitle}
        description={description}
        places={places}
        imageSrc={imageSrc}
      />
    </Layout>
  )
}

export default FitnessCamps
